exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._10pbLucKMHp-GXEgOsr5lG *{box-sizing:border-box}._10pbLucKMHp-GXEgOsr5lG{position:relative;overflow:auto;color:#000;background:#f5f5f5;width:80%;height:auto;opacity:1;box-sizing:border-box;border-radius:10px !important}@media(min-width: 769px){._10pbLucKMHp-GXEgOsr5lG{width:auto}}._3mbmN3RbUbi-hvbJG5lIdk{position:fixed;top:0;left:0;opacity:1;display:flex;align-items:center;justify-content:center;width:100vw;height:100vh;overflow:auto;z-index:1000;background:rgba(0,0,0,.5)}._3bUbuop8b6XyRPurqV8Mjm{width:90vw;height:90vh}@media screen and (max-width: 1024px){._3bUbuop8b6XyRPurqV8Mjm{width:98vw;height:93vh;border-radius:.5em !important;margin-top:2em}}._1OtAJI4xpV_3rZibJz5SIr{width:100%}@media(min-width: 769px){._1OtAJI4xpV_3rZibJz5SIr{width:50%;margin:0}}@media(min-width: 992px){._1OtAJI4xpV_3rZibJz5SIr{width:37%}}._3tKgRI4RPHUpsiT7Dzc9Da{width:100%}@media(min-width: 769px){._3tKgRI4RPHUpsiT7Dzc9Da{width:52%}}@media(min-width: 992px){._3tKgRI4RPHUpsiT7Dzc9Da{width:30%}}._1J2YNL6RI0z9WLnRPsKtrd{overflow:visible;border-radius:10px !important}", ""]);

// Exports
exports.locals = {
	"modal": "_10pbLucKMHp-GXEgOsr5lG",
	"modalBackdrop": "_3mbmN3RbUbi-hvbJG5lIdk",
	"modalLg": "_3bUbuop8b6XyRPurqV8Mjm",
	"modalMd": "_1OtAJI4xpV_3rZibJz5SIr",
	"modalSm": "_3tKgRI4RPHUpsiT7Dzc9Da",
	"modalContentOutside": "_1J2YNL6RI0z9WLnRPsKtrd"
};