exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._30P0neX30uyCXyftZRc-wd{padding:5px 10px;margin:5px 0;background:#f5f5f5;width:100%;border-radius:10px !important;border:1px solid #eaeaea;display:flex;justify-content:space-between;align-items:center;border-radius:35px !important}._20h-4rh-ZOkDzcZV3OqpF1{opacity:.6}@media(min-width: 576px){._30P0neX30uyCXyftZRc-wd{padding:10px}}@media(min-width: 769px){._30P0neX30uyCXyftZRc-wd{width:80%;padding:12px;margin:10px 0}}._30P0neX30uyCXyftZRc-wd ._2DWFzclPxe9pCQFh17rZLz{display:flex}._30P0neX30uyCXyftZRc-wd .Y9MBOxh0ftBwuG9TDq_pG{padding-right:8px}@media(min-width: 576px){._30P0neX30uyCXyftZRc-wd .Y9MBOxh0ftBwuG9TDq_pG{padding:0 12px}}._30P0neX30uyCXyftZRc-wd ._1ysAQBb0JoWWAKwoGEB6jR{color:#343436;font-size:11px;margin:0}@media(min-width: 576px){._30P0neX30uyCXyftZRc-wd ._1ysAQBb0JoWWAKwoGEB6jR{font-size:14px}}._30P0neX30uyCXyftZRc-wd .lhlWRyHv17buJJvK4qkGs{font-weight:bold}._30P0neX30uyCXyftZRc-wd ._3DR2KfIkd-avtZs4G-L9wq{display:block;cursor:pointer}@media(min-width: 769px){._30P0neX30uyCXyftZRc-wd ._3DR2KfIkd-avtZs4G-L9wq{display:none}}._30P0neX30uyCXyftZRc-wd ._2ue47Cl_BPV11-6GBr79N8{font-family:\"Icons\";font-size:1.5em;color:#c5c5c5}._30P0neX30uyCXyftZRc-wd ._3sdc0KEcLuQnVJwA2DLEE1{font-weight:600;font-size:15px;background-color:#662d91;box-shadow:0 2px 0 #662d91;color:#fff;padding:8px 40px;display:none;border-radius:6px !important;border-radius:20px !important;text-shadow:1px 1px 3px #662d91;border:1px solid #662d91;background-image:linear-gradient(360deg, #8a42c1, #4f0d82 50%, #662d91 0);color:#fff;box-shadow:0 3px 0 #662d91}@media(min-width: 769px){._30P0neX30uyCXyftZRc-wd ._3sdc0KEcLuQnVJwA2DLEE1{display:inline-block}}", ""]);

// Exports
exports.locals = {
	"betContainer": "_30P0neX30uyCXyftZRc-wd",
	"betContainer--opacity": "_20h-4rh-ZOkDzcZV3OqpF1",
	"betInformation": "_2DWFzclPxe9pCQFh17rZLz",
	"informationColumn": "Y9MBOxh0ftBwuG9TDq_pG",
	"informationText": "_1ysAQBb0JoWWAKwoGEB6jR",
	"informationText--bold": "lhlWRyHv17buJJvK4qkGs",
	"stepNext": "_3DR2KfIkd-avtZs4G-L9wq",
	"stepNextIcon": "_2ue47Cl_BPV11-6GBr79N8",
	"betBtn": "_3sdc0KEcLuQnVJwA2DLEE1"
};